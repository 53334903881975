import { MouseEventHandler, useMemo } from 'react';

import { nanoid } from 'nanoid';
import { useDrag } from 'react-dnd';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import CheckIcon from '@mui/icons-material/Check';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';

import VisibilityButton from 'src/components/VisibilityButton';
import LineIcon from 'src/components/icons/LineIcon';
import MultiFramePolygonIcon from 'src/components/icons/MultiFramePolygonIcon';
import PointIcon from 'src/components/icons/PointIcon';
import PolygonIcon from 'src/components/icons/PolygonIcon';
import useAddFinding from 'src/hooks/tasks/useAddFinding';
import useRemoveFinding from 'src/hooks/tasks/useRemoveFinding';
import {
  DropItem,
  DropResult,
  FindingShape,
  LocalFinding,
  ProjectType,
} from 'src/interfaces';
import { jobState } from 'src/states/job';
import { operationModeState } from 'src/states/operationMode';
import { projectState } from 'src/states/project';
import { taskState } from 'src/states/task';
import FindingUtils from 'src/utils/finding';

interface Props {
  disabled?: boolean;
  selected?: boolean;
  finding: LocalFinding;
  onClick: () => void;
}

const FindingItem = ({
  selected = false,
  disabled = false,
  finding,
  onClick,
}: Props): JSX.Element => {
  const setIsAnnotating = useSetRecoilState(jobState.isAnnotating);
  const setLocalFindings = useSetRecoilState(taskState.localFindings);
  const operationMode = useRecoilValue(operationModeState.current);
  const isConfirmedProject = useRecoilValue(projectState.isConfirmed);
  const projectType = useRecoilValue(projectState.type);
  const isDBTProject = useRecoilValue(projectState.isDBT);
  const isMMGProject = projectType === ProjectType.MAMMOGRAPHY;
  const allowFindingGroups = isDBTProject || isMMGProject;
  const isDraggable =
    !isConfirmedProject && allowFindingGroups && !finding.viewOnly;

  const removeFinding = useRemoveFinding();
  const addFinding = useAddFinding();

  const [, drag] = useDrag<DropItem>(() => ({
    type: 'finding',
    item: {
      index: finding.index,
      view: finding.image,
      group: finding.group || '',
    },
    end: (draggedItem, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();

      setLocalFindings(prev =>
        prev.map(finding => {
          if (!dropResult) return finding;

          const isDraggedItem = finding.index === draggedItem.index;
          const wasInUnsetGroup =
            dropResult.type === 'UNSET_GROUP' &&
            finding.group === draggedItem.group;
          const shouldResetThisGroupConfirmation =
            dropResult.type === 'SET_GROUP' &&
            dropResult.group !== draggedItem.group;

          /**
           * if dragging a finding INTO a group:
           *  - set the dragged finding's `finding.group` to the target group name
           *  - unconfirm the dragged finding
           */
          if (isDraggedItem && shouldResetThisGroupConfirmation) {
            return {
              ...finding,
              confirmed: false,
              group: dropResult.group,
            };
          }

          /**
           * if dragging a finding INTO a group:
           *  - unconfirm ALL group members (including the newly added finding)
           * */
          if (
            shouldResetThisGroupConfirmation &&
            finding.group === dropResult.group
          ) {
            return { ...finding, confirmed: false };
          }

          /**
           * if dragging a finding OUT of a group:
           *  - DO NOT modify its former group mates
           *  - set the dragged finding's `finding.confirmed` to false
           *  - set the dragged finding's `finding.group` to a random string
           */
          if (wasInUnsetGroup) {
            return {
              ...finding,
              confirmed: isDraggedItem ? false : finding.confirmed,
              group: isDraggedItem ? nanoid(5) : finding.group,
            };
          }
          return finding;
        })
      );
      setIsAnnotating(true);
    },
  }));

  const getIcon = (shape: LocalFinding['shape']): JSX.Element | null => {
    switch (shape) {
      case FindingShape.LINE:
        return <LineIcon />;
      case FindingShape.POINT:
        return <PointIcon />;
      case FindingShape.POLYGON:
        return <PolygonIcon />;
      case FindingShape.MULTI_FRAME_POLYGON:
        return <MultiFramePolygonIcon />;
      case FindingShape.BOX:
        return <HighlightAltIcon />;
      default:
        return null;
    }
  };

  const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();
    event.stopPropagation();
    onClick();
  };

  const handleToggleVisibility: MouseEventHandler<
    HTMLButtonElement
  > = event => {
    event.preventDefault();
    event.stopPropagation();

    if (operationMode.isEditable) {
      setLocalFindings(prev =>
        prev.map(item => {
          if (item.index === finding.index) {
            return {
              ...item,
              hidden: !item.hidden,
            };
          } else {
            return item;
          }
        })
      );
    }
    // TODO: support for AI prediction findings
  };

  const handleClone: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();
    event.stopPropagation();
    // no need to change index as new maxIndex
    // will be assigned in addFinding itself
    // and viewOnly field should be changed to false
    // in order to be able to save it as a normal finding
    // as well as confirmed
    addFinding({ ...finding, viewOnly: false, confirmed: false });
  };

  const handleRemove: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();
    event.stopPropagation();
    removeFinding(finding.index);
  };

  const findingIdCircleNumber = useMemo(() => {
    if (!finding.labels) return null;

    const findingId = finding.labels.find(
      label =>
        // non-3D (FFDM and S2D) findings have `label.name: lesionID || findingID`
        // 3D findings have `label.name: m_lesionID || m_findingID`
        label.name.includes('lesionID') || label.name.includes('findingID')
    );

    const findingIdLabel = findingId?.value;
    if (
      !findingIdLabel ||
      typeof findingIdLabel === 'string' ||
      typeof findingIdLabel === 'boolean'
    )
      return null;

    let findingIdResult: string | null = null;

    Object.keys(findingIdLabel).forEach(key => {
      const isKeySelected = findingIdLabel[key];
      if (isKeySelected) {
        findingIdResult = FindingUtils.circleNumbers[key] ?? null;
      }
    });
    return findingIdResult;
  }, [finding.labels]);

  return (
    <Container
      ref={isDraggable ? drag : undefined}
      href="#"
      onClick={handleClick}
      aria-selected={selected}
      disabled={disabled}
      data-test-id="finding-item"
    >
      <SmallIconButton>
        {isDraggable && <DragIndicatorIcon fontSize="small" />}
      </SmallIconButton>
      <VisibilityButton
        hidden={!!finding.hidden}
        disabled={disabled || !operationMode.isEditable}
        onClick={handleToggleVisibility}
      />
      <DeleteOrCopyButton
        onClick={finding.viewOnly ? handleClone : handleRemove}
        disabled={isConfirmedProject || !operationMode.isEditable}
        data-test-id={`findingItem-${finding.viewOnly ? 'copy' : 'delete'}-btn`}
      >
        {finding.viewOnly ? (
          <ContentCopyOutlinedIcon fontSize="small" />
        ) : (
          <DeleteForeverOutlinedIcon fontSize="small" />
        )}
      </DeleteOrCopyButton>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {getIcon(finding.shape)}
          <Box>{finding.index}</Box>
          {findingIdCircleNumber && <Box>{findingIdCircleNumber}</Box>}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Box sx={{ fontSize: '0.85rem', opacity: 0.5 }}>{finding.image}</Box>
          {!!finding.confirmed && (
            <Box>
              <CheckIcon
                fontSize="small"
                data-test-id="confirmed-finding-icon"
              />
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default FindingItem;

const Container = styled(Button)(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing(1)};

  width: 100%;
  padding: 
    ${theme.spacing(0.5)} 
    ${theme.spacing(1)}
    ${theme.spacing(0.5)} 
    ${theme.spacing(0.25)}
    ;

  color: var(--ctl-color-active);
  font-size: 1rem;
  line-height: normal;

  border-radius: var(--ctl-border-radius);
  cursor: pointer;
  background-color: var(--ctl-background-color-light);
  transition: background-color var(--ctl-transition-timing);

  &:hover {
    background-color: var(--ctl-background-color-lightest);
  }

  &[aria-selected='true'] {
    background-color: var(--ctl-brand-color);
    &:hover {
      background-color: var(--ctl-brand-color-light);
    }
  }

  &.Mui-disabled {
    opacity: 0.5;
  }
`
);

const SmallIconButton = styled(ButtonBase)(
  () => `
  &.Mui-disabled {
    opacity: 0.5;
  }
`
);

const DeleteOrCopyButton = styled(ButtonBase)(
  () => `
  padding: 1px;
  border-radius: 3px;
  transition: background-color 0.3s ease;
  &.Mui-disabled {
    opacity: 0.5;
  }
  &:hover {
    background-color: var(--ctl-background-color-lightest);
  }
`
);
