import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { object, string, AnyObject, ObjectSchema } from 'yup';

import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

import StyledDialog from 'src/components/modals/StyledDialog';
import StyledDialogContent from 'src/components/modals/StyledDialogContent';
import useAlert from 'src/hooks/useAlert';
import useErrorHandler from 'src/hooks/useErrorHandler';
import useLocalJobList from 'src/pages/workspace/aside/useLocalJobList';
import {
  postReport as postReportService,
  PostReportProps,
} from 'src/services/reports';
import { jobState, useRefreshCachedJob } from 'src/states/job';
import jobIdListState from 'src/states/jobIdList';

const PREFIX = 'AddReportModal';

const classes = {
  root: `${PREFIX}-root`,
  contained: `${PREFIX}-contained`,
};

const PREDEFINED_REPORT_MESSAGES = [
  'Too long to load',
  'Error on image load',
  'Poor image quality',
  'Incorrect image',
  'Received error message',
  'Other',
];

const StyledTextField = styled(TextField)({
  [`& .${classes.root}`]: {
    fontSize: '1rem',
  },
  [`& .${classes.contained}`]: {
    marginLeft: 0,
    marginRight: 0,
  },
});

interface Props {
  visibility: boolean;
  onClose: () => void;
}

interface ReportForm {
  reportText: string;
  moreDesc?: string;
}

const AddReportModal = ({ visibility, onClose }: Props): JSX.Element => {
  const currentJobId = useRecoilValue(jobIdListState.currentJobId);
  const refreshCachedJob = useRefreshCachedJob(currentJobId);
  const setIsAnnotating = useSetRecoilState(jobState.isAnnotating);
  const { onReportJob } = useLocalJobList();

  const postReport = useErrorHandler(postReportService);
  const { open: openAlert } = useAlert();

  const schema: ObjectSchema<ReportForm, AnyObject> = object().shape({
    reportText: string().required(),
    moreDesc: string(),
  });

  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
    reset,
  } = useForm<ReportForm>({
    resolver: yupResolver(schema),
  });

  const handleSubmit = handleFormSubmit(async data => {
    const payload: PostReportProps = {
      text: `${data.reportText} ${data.moreDesc ? ': ' + data.moreDesc : ''}`,
      jobId: currentJobId || '',
    };

    await postReport(payload);

    openAlert({
      type: 'success',
      message: 'Your report has been received.',
    });

    if (payload.jobId) {
      // somewhat hacky solution for refreshing job list when reporting a job
      // Using `refreshJobList()` here did not work as expected:
      // - the  modal would remain open or the job list would not update
      refreshCachedJob();
      onReportJob(payload.jobId);
    }

    setIsAnnotating(false);
    handleClose();
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <StyledDialog open={visibility} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">Add New Report</DialogTitle>
      <StyledDialogContent>
        <StyledForm onSubmit={handleSubmit} noValidate={true}>
          <FormControl fullWidth size="small">
            <InputLabel id="report-select-label">
              Select report message
            </InputLabel>
            <Select
              required
              defaultValue=""
              variant="outlined"
              label="Select report message"
              {...register('reportText')}
              error={!!errors.reportText?.message}
              data-test-id="report-message-select-box"
              labelId="report-select-label"
            >
              {PREDEFINED_REPORT_MESSAGES.map(reportMsg => (
                <MenuItem key={reportMsg} value={reportMsg}>
                  {reportMsg}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errors.reportText?.message && (
            <div style={{ color: '#f44336' }}>
              <span>{errors.reportText?.message}</span>
            </div>
          )}
          <StyledTextField
            variant="outlined"
            label="more description"
            type="text"
            multiline
            fullWidth
            minRows={2}
            FormHelperTextProps={{ classes }}
            {...register('moreDesc')}
          />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            data-test-id="submit-report-btn"
          >
            Submit
          </Button>
        </StyledForm>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default AddReportModal;

const StyledForm = styled('form')`
  margin: 16px 0;
  width: 480px;

  > * {
    margin-bottom: 8px;
  }
`;
