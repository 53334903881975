import {
  useRef,
  createRef,
  useMemo,
  Suspense,
  useCallback,
  useEffect,
} from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { styled } from '@mui/material/styles';

import {
  adjustWindowCenter,
  adjustWindowWidth,
  updateViewport,
  zoomMiddleCenter,
} from '@InsightViewer/behaviors/updateViewport';
import { CornerstoneViewerLike, Point } from '@InsightViewer/types';

import { MultiView } from 'src/components/viewers/MultiView';
import LoadingViewer from 'src/components/viewers/loading';
import Viewer from 'src/components/viewers/mammography/Viewer';
import useShortcuts from 'src/hooks/useShortcuts';
import { FindingShape, LocalFinding } from 'src/interfaces/job';
import { ShortcutDefinition } from 'src/interfaces/shortcut';
import controlState from 'src/states/control';
import imageState from 'src/states/image';
import { taskState } from 'src/states/task';

import DBTViewer3DContainer from './DBTViewer3DContainer';

interface ViewerProps {
  findings: LocalFinding[];
  findingIndex?: number;
  setFindingIndex: (index: number | undefined) => void;
  addFinding: (finding: LocalFinding) => void;
}

const DBTViewer = ({
  findings,
  addFinding,
  findingIndex,
  setFindingIndex,
}: ViewerProps): JSX.Element => {
  const control = useRecoilValue(controlState.current);
  const invert = useRecoilValue(controlState.invert);
  const flip = useRecoilValue(controlState.flip);
  const setSelectedDBTScanType = useSetRecoilState(
    taskState.selectedDBTScanType
  );
  const hasFFDMViews = useRecoilValue(imageState.hasFFDMViews);
  const hasS2DViews = useRecoilValue(imageState.hasDBTS2DViews);
  const viewsForSelectedScanType = useRecoilValue(
    imageState.dbtViewTypesForCurrentScanType
  );
  const resetTime = useRecoilValue(controlState.resetTime);

  useEffect(() => {
    // (FFDM)2D mode is default for DBT. Switches to other scan types if DBT job has no FFDM(2D) images
    if (hasFFDMViews) return setSelectedDBTScanType('FFDM');

    return setSelectedDBTScanType(hasS2DViews ? 'S2D' : '3D');
  }, [hasFFDMViews, hasS2DViews, setSelectedDBTScanType]);

  const elementsRef = useRef(
    viewsForSelectedScanType.map(() => createRef<CornerstoneViewerLike>())
  );

  const add2DPolygonFinding = useCallback(
    (imageKey: string) => (points: Point[]) => {
      addFinding({
        index: -1,
        image: imageKey,
        points,
        shape: FindingShape.POLYGON,
        confirmed: false,
      });
    },
    [addFinding]
  );

  const shortcuts = useMemo<ShortcutDefinition[]>(
    () => [
      {
        shortcut: 'zoomIn',
        callback: () => {
          elementsRef.current.map(ref =>
            updateViewport(ref, zoomMiddleCenter(0.25))
          );
        },
      },
      {
        shortcut: 'zoomOut',
        callback: () => {
          elementsRef.current.map(ref =>
            updateViewport(ref, zoomMiddleCenter(-0.25))
          );
        },
      },
      {
        shortcut: 'adjustWindowCenterUp',
        callback: () => {
          elementsRef.current.map(ref =>
            updateViewport(ref, adjustWindowCenter(0.05))
          );
        },
      },
      {
        shortcut: 'adjustWindowCenterDown',
        callback: () => {
          elementsRef.current.map(ref =>
            updateViewport(ref, adjustWindowCenter(-0.05))
          );
        },
      },
      {
        shortcut: 'adjustWindowWidthUp',
        callback: () => {
          elementsRef.current.map(ref =>
            updateViewport(ref, adjustWindowWidth(0.05))
          );
        },
      },
      {
        shortcut: 'adjustWindowWidthDown',
        callback: () => {
          elementsRef.current.map(ref =>
            updateViewport(ref, adjustWindowWidth(-0.05))
          );
        },
      },
    ],
    []
  );
  useShortcuts(shortcuts);

  return (
    <Container>
      <MultiViewContainer>
        {viewsForSelectedScanType?.map(({ name, type }, i) =>
          type === 'multiple' ? (
            <DBTViewer3DContainer
              view={name}
              viewerRef={elementsRef.current[i]}
              viewerIndex={i}
              key={name}
            />
          ) : (
            <Suspense fallback={<LoadingViewer step={'IMAGE'} />} key={name}>
              <Viewer
                view={name}
                viewerRef={elementsRef.current[i]}
                viewerIndex={i}
                control={control}
                flip={flip}
                invert={invert}
                resetTime={resetTime}
                findings={findings.filter(f => f.image === name)}
                addFinding={add2DPolygonFinding(name)}
                findingIndex={findingIndex}
                setFindingIndex={setFindingIndex}
              />
            </Suspense>
          )
        )}
      </MultiViewContainer>
    </Container>
  );
};

export default DBTViewer;

const Container = styled('div')`
  width: 100%;
  height: 100%;
`;

const MultiViewContainer = styled(MultiView)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;
